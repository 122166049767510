<template>
  <section class="section hero is-fullheight has-bg-img">
    <div class="hero-body">

      <div class="container">
        <div class="columns is-centered">

          <div class="column is-two-fifths is-centered px-0">

            <card-component title="Login | TowerEye Portal" icon="lock" icon-color="is-primary" :has-card-header-background="true">

              <form @submit.prevent="submit" method="POST">
                <b-field label="E-mail Address">
                  <b-input v-model="form.email" type="email" required autofocus/>
                </b-field>

                <b-field label="Password"
                         :message="this.email_error">
                  <b-input v-model="form.password" type="password" name="password" required/>
                </b-field>

                <hr>

                <b-field>
                  <div class="control">
                    <b-button native-type="submit"  type="is-primary" :loading="isLoading" expanded>Login</b-button>
                  </div>

                </b-field>

                <b-notification>Don't have an account yet? contact <a href="mailto:servicedesk@towereye.be">servicedesk@towereye.be</a> to request yours.</b-notification>
              </form>

            </card-component>
            <p class="has-text-white has-text-centered">
              <a class="has-text-white"  @click="gotoreset">Forgot Password</a> &nbsp;·&nbsp;
              <a class="has-text-white" href="https://towereye.be">More Info</a> &nbsp;·&nbsp;
              <a class="has-text-white" href="mailto:info@towereye.be">Contact Us</a>

            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>

</template>

<script>
import CardComponent from '@/components/CardComponent'
import auth from "@/store/modules/auth.store"
import settings from "@/store/modules/settings.store"
import store from "../../store";
import EyeIcon from "../../components/EyeIcon";


export default {
  name: 'Login',
  components: {EyeIcon, CardComponent },
  data () {
    return {
      isLoading: false,
      show: true,
      form: {
        email: null,
        password: null,
        remember: false
      },
      email_error: ""
    }
  },
  created() {
    if(!auth.isRegistered) {
      this.$store.registerModule('auth', auth)
      auth.isRegistered = true
    }
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }

    // this.$store.commit('settings/asideRightToggle', false)
    // this.$store.commit('settings/asideMobileStateToggle', false)
    // this.$store.commit('settings/isNavbarVisible', false)


    this.$store.commit('settings/fullPage', true)
    this.$store.commit('settings/asideRightToggle', false)


  },
  beforeDestroy () {
    if(this.show){
      this.$store.commit('settings/fullPage', false)
    }

  },
  methods: {

    gotoreset() {
      this.show = false
      this.$router.push({ name:'password-recovery'})
    },
    submit () {
      this.isLoading = true
      let thisIns = this;
      this.$store.dispatch('login', {
        email: thisIns.form.email,
        password: thisIns.form.password
      })
      .then(() => {
        this.isLoading = false
        this.$buefy.snackbar.open(`Successfully logged in`)
        this.$route.query.redirect ? this.$router.push(this.$route.query.redirect) :  this.$router.push('/');
      })
      .catch(error => {
        this.isLoading = false
        if(error.response.data.errors.email) {
          this.email_error = error.response.data.errors.email[0]

        }
      })
    }
  }
}
</script>

<style scoped>

  .has-bg-img {
    background: linear-gradient(
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.5)
    ), url("../../../public/img/temainpage.webp") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 16;
  }


</style>
